/* 手机设备（最大宽度 768px） */
@media (max-width: 768px) {
    .responsive-header {
      height: auto !important; /* 自动高度 */
      padding: 16px !important; /* 减少内边距 */
      line-height: 1.5 !important; /* 调整行高 */
    }
  
    .responsive-logo {
      width: 32px !important; /* 缩小 Logo */
      margin-left: 0 !important; /* 移除左边距 */
    }
  
    .responsive-title {
      font-size: 20px !important; /* 缩小标题字体 */
      margin-left: 8px !important; /* 调整左边距 */
    }
  
    .responsive-nav {
      display: none !important; /* 隐藏导航链接 */
    }
  
    .responsive-buttons {
      gap: 8px !important; /* 缩小按钮间距 */
    }
  
    .responsive-button {
      width: 100px !important; /* 缩小按钮宽度 */
      height: 36px !important; /* 缩小按钮高度 */
      font-size: 14px !important; /* 缩小按钮字体 */
    }
  
    .responsive-footer {
      height: auto !important; /* 自动高度 */
      padding: 16px !important; /* 减少内边距 */
      text-align: center !important; /* 居中文本 */
    }
  
    .responsive-footer-text {
      line-height: 1.5 !important; /* 调整行高 */
    }
  }