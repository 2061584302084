body {
  margin: 0;
  font-family: Inter, Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060042;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-input::placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.ant-select-selection-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.ant-select-selector{
  background-color: unset !important;
  border: unset !important;
}

.ant-select-selection-item {
  color: #FFFFFF !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.ant-select-item {
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.ant-select-item-option-selected {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

