@media (max-width: 768px) {
    .responsive-container {
      margin: 20px !important;
      padding: 16px !important; 
    }
  
    .responsive-form {
      max-width: 100% !important; 
    }
  
    .responsive-input {
      margin-top: 8px !important; 
    }
  
    .responsive-button {
      width: 100% !important;
      margin-bottom: 8px !important;
    }
  
    .responsive-flex {
      flex-direction: column !important;
      gap: 16px !important;
    }
}