#table_a .h-row-even {
    background-color: #F0F0F0;
}

#table_a .h-row-odd {
    background-color: #FFFFFF; 
}

.htstyle {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.h1style {
    width: 251px;
    height: 24px;
    font-weight: 500;
    font-size: 20px;
    color: rgba(0,0,0,0.8);
}

.ststyle {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  line-height: 28px;
  text-align: left;
  margin-bottom: 12px;
}

.s1style {
  font-weight: 400;
  font-size: 18px;
  color: rgba(0,0,0,0.6);
  line-height: 24px;
  text-align: left;
  margin-bottom: 12px;
}


#table_a .ant-table td {
    text-align: center !important;
    width: 25%;
}

#table_a .ant-table-thead > tr > th::before {
    display: none !important;
}

#table_a .ant-table-thead > tr > th {
    background-color: #64A7E6; 
    color: white; 
    font-weight: 900;
    font-size: 20px;
    text-align: center !important;
    width: 25%;
}


.table-container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    position: relative;
}
  
@media (max-width: 1440px) {
    .table-container {
      margin: 0 115px; 
    }
}
  
  #table_a {
    width: 100%;
    border-radius: 40px; 
    overflow: hidden; 
  }
  
  #table_a .ant-table {
    table-layout: fixed;
  }
  

  .last-column-border {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 25%; 
    border: 5px solid #64A7E6;
    border-radius: 40px 40px 40px 40px;
  }

  @media (max-width: 768px) {
    .responsive-flex {
      flex-direction: column !important; 
      padding: 20px !important; 
    }
  
    .responsive-text {
      font-size: 36px !important; 
      line-height: 1.2 !important;
    }
  
    .responsive-table {
      max-width: 100% !important;
      overflow-x: auto;
    }
  
    .responsive-carousel {
      width: 100% !important; 
      margin: 0 !important;
    }
  
    .responsive-carousel .slick-slide {
      width: 100% !important;
      margin-right: 0 !important;
    }
  
    .responsive-video {
      width: 100% !important;
      padding: 0 !important;
    }
  }